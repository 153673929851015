.background{
    background-image: url(../../../public/images/footer/background.webp);
    background-size: cover;
    /* height: calc(584 / var(--auto-width)); */
    padding-top: calc(108 / var(--auto-width));
}
.background .detail{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: calc(91 / var(--auto-width));
    height: calc(60 / var(--auto-width));
}
.phone,.we-chat{
    width: calc(32 / var(--auto-width));
    height: calc(32 / var(--auto-width));
}
.phone{
    margin-right: calc(27 / var(--auto-width));
}
.detail-box{
    display: flex;
    justify-content: space-between;
}
.detail-item span{
    font-size: calc(18 / var(--auto-width));
    color: white;
    display: block;
    margin-bottom: calc(8 / var(--auto-width));
}
.detail-item{
    width: calc(220 / var(--auto-width));
    margin-left: calc(34 / var(--auto-width));
}
.detail-item button{
    display: block;
    font-size: calc(16 / var(--auto-width));
    line-height: calc(32 / var(--auto-width));
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    margin-top: calc(8 / var(--auto-width));
}
.detail-item button:hover{
    color: white;
}
.input-container input{
    width: calc(449 / var(--auto-width));
    height: calc(48 / var(--auto-width));
    border:rgba(243,238,255,10%) solid calc(1 / var(--auto-width));
    border-radius: calc(11 / var(--auto-width));
    margin-right: calc(11 / var(--auto-width));
    background-color: transparent;
    color: white;
    padding: 0 calc(16 / var(--auto-width));
}
.input-container button{
    border:rgba(243,238,255,8%)  solid calc(1 / var(--auto-width));
    border-radius: calc(11 / var(--auto-width));
    background: linear-gradient(to bottom, rgba(243,238,255,0%) 0%, rgba(243,238,255,4%) 100%);
    width: calc(79 / var(--auto-width));
    height: calc(50 / var(--auto-width));
    color: white;
    font-size: calc(18 / var(--auto-width));
}
.anhao {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10 / var(--auto-width));
    height:calc(205 / var(--auto-width)) ;
    margin-top: calc(20 / var(--auto-width));
    align-items: center;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  .row img {
    height: 50%;
  }
  .columline {
    margin-left: calc(24 / var(--auto-width));
    margin-right: calc(16 / var(--auto-width));
    height: 50%;
    width: calc(1 / var(--auto-width));
    background: white;
  }
  .anhao-copy{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:calc(1 / var(--auto-width)) ;
  }
  .address{
    font-family: 'AlibabaPuHuiTi';
    line-height: calc(16 / var(--auto-width));
  }