.ai-presenter-container {
  background: url(/public/images/products/ai_presenter_header.webp);
  background-size: cover;
}
.ai-presenter-product {
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ai-presenter-product1 {
  background: url(/public/images/products/ai_presenter_product_back.webp);
  background-size: cover;
}
.ai-presenter-product-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ai-presenter-product-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ai-presenter-product-container1 {
  flex-direction: row-reverse;
  position: relative;
}
.ai-presenter-product-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.ai-presenter-product-left span {
  color: white;
}
.ai-presenter-product-left span:nth-of-type(1) {
  font-size: calc(48 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  line-height: calc(56 / var(--auto-width));
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.4) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ai-presenter-product-left span:nth-of-type(2) {
  font-size:calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin: calc(8 / var(--auto-width)) 0 calc(24 / var(--auto-width));
}
.ai-presenter-product-left span:nth-of-type(3) {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  line-height: calc(32 / var(--auto-width));
}
.ai-presenter-product-right img {
  width: calc(373 / var(--auto-width));
  height: calc(810 / var(--auto-width));
}
.ai_image {
  display: flex;
}
.ai_image img {
  width: 50%;
  height: 100%;
}
.ai-presenter-product-back1 {
  position: absolute;
  width: calc(1093 / var(--auto-width)) !important;
  height: 100% !important;
  left: calc(-360 / var(--auto-width));
}
