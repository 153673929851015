.product-carousel-item {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-carousel-item1 {
  background: url(/public/images/products/ai_cell_carousel1.webp);
  background-size: cover;
}
.product-carousel-item2 {
  background: url(/public/images/products/bsd_carousel1.webp);
  background-size: cover;
}
.product-carousel-item3 {
  background: url(/public/images/products/ai_educational_carousel1.webp);
  background-size: cover;
}
.product-carousel-item-span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.5s;
}
.product-carousel-item:hover .product-carousel-item-span {
  opacity: 0;
}
.product-carousel-item:hover .product-carousel-item-hover {
  opacity: 1;
}
.product-carousel-item-text {
  font-size: calc(48 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  color: white;
  line-height: calc(56 / var(--auto-width));
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.4) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product-carousel-item-div {
  font-family: "Noto Sans SC";
  font-size: calc(30 / var(--auto-width));
  color: white;
  width: calc(218 / var(--auto-width));
  height: calc(75 / var(--auto-width));
  border-radius: calc(24 / var(--auto-width));
  border: rgba(255, 255, 255, 6%) solid calc(1 / var(--auto-width));
  background: linear-gradient(to right bottom, #111214 5%, #0c0d0f 76%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(20 / var(--auto-width));
}
.product-carousel-item-hover {
  opacity: 0;
  position: absolute;
  font-size: calc(32 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  font-weight: 400;
  transition: all 0.5s;
  text-align: center;
  max-width: calc(1347 / var(--auto-width));
}
