/* 滚动内容 */
.home-carousel2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.scroll-content2 {
  height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  animation: scrollRight 15s linear infinite;
  /* 平滑滚动 */
}

.scroll-item2 img {
  width: calc(1920 / var(--auto-width));
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
    /* 从左开始 */
  }

  100% {
    transform: translateX(0%);
    /* 向右移动100% */
  }
}
