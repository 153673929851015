.ai-educational-container {
  background: url(/public/images/products/ai_educational_header.webp);
  background-size: cover;
}
.ai-educational-product {
  display: flex;
  align-items: center;
  background: url(/public/images/products/xiaoshu-product-back.webp);
  background-size: 100% 100%;
  position: relative;
}
.ai-educational-product-back{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ai-educational-product-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ai-educational-product-left {
  display: flex;
  flex-direction: column;
  width: 27.65%;
  margin-left: 18.75%;
  margin-right: 3.6%;
}
.ai-educational-product-left span {
  color: white;
}
.ai-educational-product-left span:nth-of-type(1) {
  font-size: calc(48 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  line-height: calc(56 / var(--auto-width));
  background: radial-gradient(circle, rgba(255,255,255,1) 30%,  rgba(255,255,255,0.4) 100% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ai-educational-product-left span:nth-of-type(2) {
  font-size: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin: calc(8 / var(--auto-width)) 0 calc(24 / var(--auto-width));
}
.ai-educational-product-left span:nth-of-type(3) {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  line-height: calc(32 / var(--auto-width));
}
.ai-educational-product-right {
  width: 50%;
}
.ai-educational-product-right img {
  width: 85.2%;
}
