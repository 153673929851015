.core-header {
  background: url(/public/images/core/core_header.webp);
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center right;
}
.core-header-container {
  position: absolute;
  bottom: calc(-68 / var(--auto-width));
  height: calc(558 / var(--auto-width));
  width: 100%;
  background: linear-gradient(to top, #00001e 0%, rgba(0, 0, 30, 0) 100%);
}
.core-header-box {
  position: absolute;
  bottom: calc(170 / var(--auto-width));
  right: calc(319 / var(--auto-width));
}
.core-header-text {
  display: flex;
  align-items: center;
  position: relative;
}
.core-header-text span {
  margin-left: calc(23 / var(--auto-width));
  font-size: calc(62 / var(--auto-width));
  line-height: calc(62 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
}
.alphaOS {
  display: inline-block;
  margin-left: calc(23 / var(--auto-width));
  margin-top: calc(17 / var(--auto-width));
  font-size: calc(30 / var(--auto-width));
  line-height: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: rgba(255, 255, 255, 0.8);
}
.core-content {
  background: url(/public/images/core/core_background.webp);
  padding-top: calc(189 / var(--auto-width));
  background-size: cover;
  background-position: center right;
}
.core-back-container {
  padding: 0 calc(320 / var(--auto-width));
}
.core-background {
  display: flex;
  justify-content: space-between;
  margin-top: calc(127 / var(--auto-width));
  gap: calc(155 / var(--auto-width));
}
.core-back-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  opacity: 0;
  transform: translateY(2.5rem);
  transition: opacity 0.5s, transform 0.5s ease-out;
}
.core-animate {
  opacity: 1;
  transform: translateX(0);
}
.core-back-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.core-back-text span:nth-of-type(1) {
  background: linear-gradient(to bottom, #daffcc 0%, #309bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(100 / var(--auto-width));
  font-weight: bold;
  display: inline-block;
}
.core-back-text span:nth-of-type(2) {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(36 / var(--auto-width));
  text-align: right;
}
.core-back-span {
  font-family: "AlibabaPuHuiTi";
  font-size: calc(16 / var(--auto-width));
  color: white;
  margin-top: calc(12 / var(--auto-width));
  line-height: calc(38 / var(--auto-width));
}
.core-tech-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(221 / var(--auto-width));
  padding: 0 calc(320 / var(--auto-width));
}
.core-tech-container img {
  width: 100%;
  margin-top: calc(60 / var(--auto-width));
}
.core-tech-title {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(62 / var(--auto-width));
  line-height: calc(62 / var(--auto-width));
  text-align: center;
}
.core-tech-text {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(16 / var(--auto-width));
  line-height: calc(28 / var(--auto-width));
  margin-top: calc(32 / var(--auto-width));
}
.core-app-container {
  padding: 0 calc(150 / var(--auto-width)) calc(258 / var(--auto-width));
  margin-top: calc(233 / var(--auto-width));
}
.core-app-box {
  margin-top: calc(60 / var(--auto-width));
  display: flex;
  justify-content: space-between;
  /* gap: 2.5%; */
}
.core-app-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(376 / var(--auto-width));
  align-items: center;
}
.core-app-item span {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(20 / var(--auto-width));
  line-height: calc(20 / var(--auto-width));
  font-weight: bold;
}
.core-app-item img {
  width: 100%;
  margin-bottom: calc(40 / var(--auto-width));
}
