.product-carousel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.xiaoshu-product-carousel-container1 {
  background: url(/public/images/products/xiaoshu_carousel1.webp);
  background-size: cover;
}
.xiaoshu-product-carousel-container2 {
  background: url(/public/images/products/xiaoshu_carousel2.webp);
  background-size: cover;
}
.ai-presenter-product-carousel-container1 {
  background: url(/public/images/products/ai_presenter_carousel1.webp);
  background-size: cover;
}
.ai-presenter-product-carousel-container2 {
  background: url(/public/images/products/ai_presenter_carousel2.webp);
  background-size: cover;
}
.ai-presenter-product-carousel-container3 {
  background: url(/public/images/products/ai_presenter_carousel3.webp);
  background-size: cover;
}
.ai-voice-product-carousel-container1 {
  background: url(/public/images/products/ai_voice_carousel1.webp);
  background-size: cover;
}
.ai-voice-product-carousel-container2 {
  background: url(/public/images/products/ai_voice_carousel2.webp);
  background-size: cover;
}
.mbti-product-carousel-container1 {
  background: url(/public/images/products/mbti_carousel1.webp);
  background-size: cover;
}
.mbti-product-carousel-container2 {
  background: url(/public/images/products/mbti_carousel2.webp);
  background-size: cover;
}
.mbti-product-carousel-container3 {
  background: url(/public/images/products/mbti_carousel3.webp);
  background-size: cover;
}
.ant-carousel {
  width: 100%;
  height: 100%;
}
.vieant-carousel .slick-slider {
  width: 100%;
  height: 100%;
}

/* bottom change dots */
.ant-carousel .slick-dots-bottom {
  bottom: calc(67 / var(--auto-width));
}
.ant-carousel .slick-dots li {
  width: calc(7 / var(--auto-width));
  height: calc(7 / var(--auto-width));
  background: rgba(255, 255, 255, 0.4);
  border-radius: calc(7 / var(--auto-width));
}
.ant-carousel .slick-dots li.slick-active {
  width: calc(38 / var(--auto-width));
  height: calc(7 / var(--auto-width));
  background: linear-gradient(
    225deg,
    #076eff 0%,
    #4fabff 31%,
    #b1c5ff 59%,
    #ffddb7 81%,
    #ffb7b7 100%
  );
}
.ant-carousel .slick-dots li button {
  background: transparent;
}
.ant-carousel .slick-dots li.slick-active button {
  opacity: 0;
}
.product-carousel-item-hover1 {
  opacity: 1 !important;
}
