/* html {
  font-size: calc(125vw / 256);
}
@media (max-width: 1920px) {
  html {
    font-size: calc(110vw / 192);
  }
}
@media (max-width: 1680px) {
  html {
    font-size: calc(100vw / 168);
  }
}
@media (max-width: 1432px) {
  html {
    font-size: calc(100vw / 144);
  }
}
@media (max-width: 960px) {
  html {
    font-size: calc(100vw / 96);
  }
} */

@media (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
  --auto-width:1920 * 100vw
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: gray;
}

body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: gray;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1c1d1f;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

div,
span,
p {
  cursor: default;
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
}
button:disabled {
  cursor: not-allowed;
}

button,
a,
input,
textarea {
  outline: none;
}

svg {
  overflow: visible;
}

.container {
  /* max-width: 66.67%; */
  max-width: calc(1280 / var(--auto-width));
  margin: 0 auto;
}
.height {
  height: calc(1080 / var(--auto-width));
}
.container1200 {
  max-width: calc(1200 / var(--auto-width));
}
