.home-container {
  width: 100%;
}
.video-background {
  position: relative;
  background-color: #00091b;
  z-index: -2;
}
.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(1740 / var(--auto-width));
  z-index: -1;
}
@media (max-width: 1690px) {
  .video-background video {
    height: calc(1780 / var(--auto-width));
  }
}
@media (max-width: 1065px) {
  .video-background video {
    height: calc(1829 / var(--auto-width));
  }
}
@media (max-width: 768px) {
  .video-background video {
    height: calc(1895 / var(--auto-width));
  }
}
.home-background {
  background-image: url(/public/images/home/home_background.webp);
  background-size: cover;
}
.video-back{
  position: absolute;
  width: 100%;
  bottom: calc(-180 / var(--auto-width));
}
.banner-container {
  padding-top: calc(418 / var(--auto-width));
}
.alphaMan {
  color: white;
  font-family: "RedRose";
  font-size: calc(20 / var(--auto-width));
  letter-spacing: 0.04px;
  line-height: calc(20 / var(--auto-width));
}
.infuse {
  display: block;
  font-size: calc(30 / var(--auto-width));
  color: white;
  margin-top: calc(34 / var(--auto-width));
  text-align: center;
  font-family: "RedRose";
  letter-spacing: 0.06px;
  position: relative;
}
.explore {
  margin-top: calc(275 / var(--auto-width));
  text-align: end;
}
.explore img{
  width: calc(302 / var(--auto-width));
  height: calc(30 / var(--auto-width));
}
.line {
  width: calc(3 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  background: linear-gradient(to bottom, #309bff 78%, #222 100%);
  position: absolute;
  left: 0;
}
.company-position {
  display: flex;
  justify-content: flex-end;
  padding-top: calc(303 / var(--auto-width));
  margin-bottom: calc(24 / var(--auto-width));
}
.company-box {
  width: calc(737 / var(--auto-width));
  position: relative;
  padding-left: calc(24 / var(--auto-width));
}
.company-title span {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(62 / var(--auto-width));
  line-height: calc(62 / var(--auto-width));
  display: block;
  margin-bottom: calc(24 / var(--auto-width));
}
.introduction {
  font-size: calc(16 / var(--auto-width));
  color: white;
  margin-top: calc(24 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}
.deep {
  display: flex;
  align-items: center;
  margin-top: calc(160 / var(--auto-width));
  width: calc(737 / var(--auto-width));
  padding-left: calc(24 / var(--auto-width));
}
.deep span {
  font-size: calc(24 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
  display: inline-block;
}
.deep button {
  width: calc(44 / var(--auto-width));
  height: calc(44 / var(--auto-width));
  border-radius: calc(22 / var(--auto-width));
  background-color: #046aff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(25 / var(--auto-width));
}
.deep img {
  width: calc(35 / var(--auto-width));
  height: calc(35 / var(--auto-width));
}

/* banner title animation */
.banner-title {
  position: relative;
  top: calc(18 / var(--auto-width));
}
.invisible {
  visibility: hidden;
  width:calc(1310 / var(--auto-width)) ;
}
.hero_cubespinner {
  animation-name: hero_spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  transform-style: preserve-3d;
  /* transform-origin:0% 6.25rem; */
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
}
.hero_cubespinner div {
  position: absolute;
}
.hero_cubespinner span,
.invisible {
  display: inline-block;
  inset: 0;
  text-align: center;
  background: linear-gradient(78deg, #99ffa1 0%, #3D81FF 25%, #a3f5fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "ManropeBold";
  font-size: calc(200 /var(--auto-width));
  letter-spacing: calc(-8 / var(--auto-width));
}
.hero1 span{
  background: linear-gradient(78deg, #99ffa1 0%, #4FB3FF 25%, #a3f5fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
}
.hero2 span{
  letter-spacing: calc(-6 / var(--auto-width));
}
.hero3 span{
  letter-spacing: calc(-8 / var(--auto-width));
}
.hero_cubespinner .hero1 {
  transform: rotateX(0deg) translateZ(6.25rem);
  -webkit-backface-visibility: hidden;
  cursor: pointer;
}
.hero_cubespinner .hero2 {
  transform: rotateX(120deg) translateZ(6.25rem);
  -webkit-backface-visibility: hidden;
  cursor: pointer;
  width:calc(1306 / var(--auto-width)) ;
  margin: 0 auto;
}
.hero_cubespinner .hero3 {
  transform: rotateX(240deg) translateZ(6.25rem);
  -webkit-backface-visibility: hidden;
  cursor: pointer;
  width:calc(1310 / var(--auto-width)) ;
  margin: 0 auto;
}
@keyframes hero_spincube {
  0% {
    transform: rotateX(0deg);
  }
  33% {
    transform: rotateX(120deg);
  }
  66% {
    transform: rotateX(240deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
/* @media (min-width: 1280px) {
  .hero_cubespinner {
    animation-delay: 8s;
    top: 4px;
  }
} */
/* @media (min-width: 1024px) {
  .hero_cubespinner span {
    height: 89px;
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .hero_cubespinner {
    display: inline;
    transform-origin: 45px 45px 0;
    left: 0;
    top: 0;
  }
  .hero_cubespinne .hero1 {
    transform: rotateX(0deg) translateZ(45px);
  }
  .hero_cubespinner .hero2 {
    transform: rotateX(120deg) translateZ(45px);
  }
  .hero_cubespinner .hero3 {
    transform: rotateX(240deg) translateZ(45px);
  }
} */

/* @media (min-width: 640px) {
  .hero_cubespinner .hero1 {
    transform: rotateX(0deg) translateZ(30px);
  }
  .hero_cubespinner .hero2 {
    transform: rotateX(120deg) translateZ(30px);
  }
  .hero_cubespinner .hero3 {
    transform: rotateX(240deg) translateZ(30px);
  }
} */
