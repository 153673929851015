/* nav container */
.nav {
  background-color: rgba(255, 255, 255, 1%);
  width: 100%;
  height: calc(60 / var(--auto-width));
  position: fixed;
  border-bottom: calc(1 / var(--auto-width)) solid rgba(255, 255, 255, 8%);
  backdrop-filter: blur(0.75rem);
  z-index: 999;
  top: 0;
}
.nav nav {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(413 / var(--auto-width));
}
.nav-item {
  align-items: center;
  position: relative;
}
.nav-item button {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
  background-color: transparent;
  border: none;
  align-items: center;
  height: calc(22 / var(--auto-width));
  padding: 0;
  height: calc(60 / var(--auto-width));
  position: relative;
}
.nav-item:hover .hover-container {
  opacity: 1;
  pointer-events: all;
  top: 100%;
}
.logo-img {
  width: calc(80 / var(--auto-width));
  height: calc(60 / var(--auto-width));
}
.arrow-img {
  vertical-align: middle;
  width: calc(14 / var(--auto-width));
  height: calc(14 / var(--auto-width));
  margin-left: calc(10 / var(--auto-width));
  transition: all 0.5s;
}
.nav-item:hover .arrow-img {
  transform: rotate(-180deg);
}

/* hover container */
.hover-container {
  background-color: #222;
  border-radius: calc(12 / var(--auto-width));
  position: absolute;
  left: 50%;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
  top: 110%;
}

.hover-container button {
  color: white;
  display: block;
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  z-index: 99;
}
.hover-container button:hover {
  color: #046aff;
  cursor: pointer;
}
.hover-container img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-bottom-right-radius: calc(12 / var(--auto-width));
  border-bottom-left-radius: calc(12 / var(--auto-width));
}
.nav-company-container {
  width: calc(236 / var(--auto-width));
  padding: calc(42 / var(--auto-width)) calc(86 / var(--auto-width))
    calc(36 / var(--auto-width));
  flex-direction: column;
  justify-content: space-between;
  margin-left: calc(-118 / var(--auto-width));
}
.nav-core-container {
  padding: calc(42 / var(--auto-width)) calc(27 / var(--auto-width))
    calc(36 / var(--auto-width)) calc(32 / var(--auto-width));
  width: calc(236 / var(--auto-width));
  margin-left: calc(-118 / var(--auto-width));
}
.nav-products-container {
  display: flex;
  justify-content: space-between;
  padding: calc(42 / var(--auto-width)) calc(66 / var(--auto-width))
    calc(36 / var(--auto-width));
  width: calc(824 / var(--auto-width));
  left: -600%;
}
.nav-products-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nav-item:hover .nav-products-container {
  display: flex;
  justify-content: space-between;
}
.active-index {
  position: absolute;
  bottom: calc(9.5 / var(--auto-width));
  width: calc(6 / var(--auto-width));
  height: calc(6 / var(--auto-width));
  border-radius:calc(3 / var(--auto-width)) ;
  left: 50%;
  margin-left: calc(-3 / var(--auto-width));
}
