.product-introduction-product {
  display: flex;
  align-items: center;
  background: url(/public/images/products/xiaoshu-product-back.webp);
  background-size: 100% 100%;
  position: relative;
  padding-left: calc(360 / var(--auto-width));
}
.product-introduction-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-introduction-container {
  max-width: calc(1560 / var(--auto-width));
  display: flex;
  align-items: center;
}
.product-introduction-left {
  display: flex;
  flex-direction: column;
  width: calc(528 / var(--auto-width));
  margin-right: calc(72 / var(--auto-width));
}
.product-introduction-left span {
  color: white;
}
.product-introduction-left span:nth-of-type(1) {
  font-size: calc(48 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  line-height: calc(56 / var(--auto-width));
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.4) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product-introduction-left span:nth-of-type(2) {
  font-size: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin: calc(8 / var(--auto-width)) 0 calc(24 / var(--auto-width));
}
.product-introduction-left span:nth-of-type(3) {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  line-height: calc(32 / var(--auto-width));
}
.product-introduction-img {
  width: calc(893 / var(--auto-width));
}
.product-introduction-img img {
  width: 100%;
  margin-left: calc(67 / var(--auto-width));
}
