.xiaoshu-container {
  height: calc(1080 / var(--auto-width));
  padding-top: calc(344 / var(--auto-width));
}
.xiaoshu {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.xiaoshu span {
  color: white;
}
.xiaoshu-smart {
  font-family: "SofiaProSemiBold";
  font-size: calc(80 / var(--auto-width));
  background: linear-gradient(
    225deg,
    #076eff 0%,
    #4fabff 31%,
    #b1c5ff 59%,
    #ffddb7 81%,
    #ffb7b7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.xiaoshu-zhihui {
  margin: calc(24 / var(--auto-width)) 0;
  font-size: calc(36 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}
.xiaoshu-buttons {
  display: flex;
  align-items: center;
}
.xiaoshu-buttons button {
  background: linear-gradient(225deg, #111214 5%, #0c0d0f 76%);
  padding: 0 calc(16 / var(--auto-width));
  border-radius: calc(24 / var(--auto-width));
  border: rgba(255, 255, 255, 6%) solid calc(1 / var(--auto-width));
  color: white;
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin-right: calc(16 / var(--auto-width));
  height:calc(39 / var(--auto-width)) ;
}
.xiaoshu-explore {
  display: flex;
  align-items: center;
  margin-top: calc(100 / var(--auto-width));
}
.xiaoshu-explore div {
  border-radius: calc(8 / var(--auto-width));
  width: calc(41 / var(--auto-width));
  height: calc(41 / var(--auto-width));
  background-color: #0e0f11;
  margin-right: calc(12 / var(--auto-width));
  display: flex;
  align-items: center;
  justify-content: center;
}
.xiaoshu-explore img {
  width: calc(12 / var(--auto-width));
  height:calc(15 / var(--auto-width));
}
.xiaoshu-explore span {
  font-size: calc(24 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  color: white;
}
