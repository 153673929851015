.rongyuzizhi {
  height: calc(1003 / var(--auto-width));
  overflow: hidden;
  margin-top: calc(88 / var(--auto-width));
}
.rongyuzizhi-box {
  width: calc(331 / var(--auto-width));
  height: calc(725 / var(--auto-width));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rongyuzizhi-box:nth-child(odd) {
  background: url(/public/images/company/background_bottom.webp);
  background-size: 100% 100%;
}
.rongyuzizhi-box:nth-child(even) {
  background: url(/public/images/company/background_top.webp);
  background-size: 100% 100%;
  margin-top: calc(278 / var(--auto-width));
}
.rongyuzizhi-box1-image1 {
  width: calc(191 / var(--auto-width));
  height: calc(235 / var(--auto-width));
}
.rongyuzizhi-box1-image2 {
  width: calc(218 / var(--auto-width));
  height: calc(121 / var(--auto-width));
}
.rongyuzizhi-box2-image1 {
  width: calc(218 / var(--auto-width));
  height: calc(411 / var(--auto-width));
}
.rongyuzizhi-box2-image2 {
  width: calc(178 / var(--auto-width));
  height: calc(177 / var(--auto-width));
}

/* 滚动内容 */
.rongyuzizhi-scroll-content {
  height: 100%;
  width: max-content;
  display: flex;
  animation: scroll 10s linear infinite;
  gap: calc(17 / var(--auto-width));
  /* 平滑滚动 */
}
/* 滚动动画 */
@keyframes scroll {
  0% {
    transform: translateX(0);
    /* 从左开始 */
  }

  100% {
    transform: translateX(-50%);
    /* 向左移动100% */
  }
}
