.xiaoshu-header-container {
  background: url(/public/images/products/xiaoshu_header.webp);
  background-size: cover;
}
.xiaoshu-product {
  background: url(/public/images/products/xiaoshu-product-back.webp);
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ai-xiaoshu-product-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.xiaoshu-product-container {
  display: flex;
  align-items: center;
  max-width: calc(1560 / var(--auto-width));
}
.xiaoshu-product-left {
  display: flex;
  flex-direction: column;
  width: calc(523 / var(--auto-width));
  margin-right: calc(77 / var(--auto-width));
}
.xiaoshu-product-left span {
  color: white;
}
.xiaoshu-product-left span:nth-of-type(1) {
  font-size: calc(48 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  line-height: calc(56 / var(--auto-width));
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.4) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.xiaoshu-product-left span:nth-of-type(2) {
  font-size: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin: calc(8 / var(--auto-width)) 0 calc(24 / var(--auto-width));
}
.xiaoshu-product-left span:nth-of-type(3) {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  line-height: calc(32 / var(--auto-width));
}
.xiaoshu-product-right {
  width: calc(955 / var(--auto-width));
}
.xiaoshu-product-right img {
  width: 100%;
}
