.home-carousel {
  width: 100%;
  margin-bottom: calc(43 / var(--auto-width));
  overflow: hidden;
  margin-top: calc(239 / var(--auto-width));
  position: relative;
  display: inline-block;
}

.home-carousel3 {
  margin-top: calc(44 / var(--auto-width));
  margin-bottom: calc(239 / var(--auto-width));
}

/* 滚动内容 */
.scroll-content {
  height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  animation: scroll 15s linear infinite forwards;
  /* 平滑滚动 */
}

.scroll-item img {
  width: calc(1920 / var(--auto-width));
}

/* 滚动动画 */
@keyframes scroll {
  0% {
    transform: translateX(0);
    /* 从左开始 */
  }

  100% {
    transform: translateX(100%);
    /* 向左移动100% */
  }
}
