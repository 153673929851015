.product-technology {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.technology_back {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.product-technology-box-container {
  display: flex;
  justify-content: center;
  gap: calc(10 / var(--auto-width));
  margin: 0 auto;
  flex-wrap: wrap;
}
.product-technology-box {
  width: calc(390 / var(--auto-width));
  text-align: center;
  opacity: 0;
  transform: translateY(2.5rem);
  padding: 0 calc(46 / var(--auto-width));
  transition: opacity 1s, transform 1s ease-out;
}
.animate {
  opacity: 1;
  transform: translateX(0);
}
.product-technology-text {
  margin-top: calc(52 / var(--auto-width));
}
.product-technology-text span {
  display: block;
  color: white;
  font-family: "AlibabaPuHuiTi";
}
.product-technology-text span:nth-of-type(1) {
  font-size: calc(24 / var(--auto-width));
}
.product-technology-text span:nth-of-type(2) {
  font-size: calc(16 / var(--auto-width));
  line-height: calc(32 / var(--auto-width));
  margin-top: calc(8 / var(--auto-width));
  text-align: left;
}
