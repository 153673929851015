.media-publicity-container {
  display: flex;
  align-items: center;
  margin-bottom: calc(66 / var(--auto-width));
}
.media-publicity-line {
  width: calc(3 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  background: linear-gradient(to bottom, #309bff 78%, #222 100%);
}
.media-publicity-title {
  font-size: calc(62 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
  margin-left: calc(24 / var(--auto-width));
}
.ant-collapse {
  background-color: transparent;
  border: none;
  color: white;
  font-size: calc(40 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}
.ant-collapse-p {
  color: white;
  line-height: calc(38 / var(--auto-width)) !important;
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  padding: calc(18 / var(--auto-width)) 0 calc(55 / var(--auto-width));
  margin: 0;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border-color: rgba(255, 255, 255, 0.4);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white;
  align-items: center;
  font-family: "AlibabaPuHuiTi";
  padding: calc(20 / var(--auto-width)) 0;
}
.media-collapse img {
  width: 100%;
  margin: calc(-19 / var(--auto-width)) 0 calc(50 / var(--auto-width));
}
.ant-collapse-arrow {
  width: calc(76 / var(--auto-width));
  height: calc(76 / var(--auto-width));
}
.ant-collapse-item-active .ant-collapse-arrow {
  transform: rotate(180deg);
}
