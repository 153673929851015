/* company abount */
.company-about {
  display: flex;
  align-items: center;
  background-image: url("/public//images//company/company_about_background.webp");
  width: 100%;
  background-size: cover;
}
.company-about-container {
  display: flex;
  align-items: center;
}
.company-about-text {
  color: white;
  font-family: "SofiaProSemiBold";
  font-size: calc(80 / var(--auto-width));
  margin-right: calc(94 / var(--auto-width));
  line-height: calc(88 / var(--auto-width));
  text-align: right;
  margin-top: calc(-30 / var(--auto-width));
}
.company-about-xiangsu {
  font-family: "AlibabaPuHuiTi";
  font-size: calc(16 / var(--auto-width));
  color: white;
  line-height: calc(38 / var(--auto-width));
}
.company-container-background {
  background: url("/public//images//company/conpany-container-background.webp");
  padding-top: calc(174 / var(--auto-width));
  background-size: cover;
}

/* company culture */
.company-culture {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.company-culture-title {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(62 / var(--auto-width));
  line-height: calc(62 / var(--auto-width));
}
.company-culture-title1 {
  padding-top: calc(174 / var(--auto-width));
}
.company-culture-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(1042 / var(--auto-width));
  margin: calc(88 / var(--auto-width)) auto calc(84 / var(--auto-width));
}
.company-culture-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  opacity: 0;
  transform: translateY(2.5rem);
  transition: opacity 0.5s, transform 0.5s ease-out;
}
.company-culture-box .image1 {
  width: calc(70 / var(--auto-width));
  height: calc(73 / var(--auto-width));
  margin-bottom: calc(36 / var(--auto-width));
}
.company-culture-box .image2 {
  width: calc(60 / var(--auto-width));
  height: calc(67 / var(--auto-width));
  margin-bottom: calc(43 / var(--auto-width));
}
.company-culture-box .image3 {
  width: calc(67 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  margin-bottom: calc(45 / var(--auto-width));
}
.company-culture-box span:nth-of-type(1) {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(30 / var(--auto-width));
}
.company-culture-box span:nth-of-type(2) {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(20 / var(--auto-width));
  margin-top: calc(18 / var(--auto-width));
}

/* fazhanlicheng */
.fazhanlicheng {
  margin-top: calc(156 / var(--auto-width));
  padding-top: calc(174 / var(--auto-width));
  padding-left: calc(194 / var(--auto-width));
  padding-right: calc(78 / var(--auto-width));
  background: url(/public/images/company/shanmai.webp);
  background-size: 100% 100%;
}
.fazhanlicheng-container {
  display: flex;
  align-items: center;
  margin-bottom: calc(54 / var(--auto-width));
  width: calc(1648 / var(--auto-width));
}
.fazhanlicheng-line {
  width: calc(3 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  background: linear-gradient(to bottom, #309bff 78%, #222 100%);
}
.fazhenglicheng-title {
  font-size: calc(62 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
  margin-left: calc(24 / var(--auto-width));
}
.fazhanlicheng-box-back {
  display: flex;
  padding-bottom: calc(429 / var(--auto-width));
  gap: calc(72 / var(--auto-width));
}
.fazhanlicheng-box {
  display: flex;
  flex: 1;
}
.fanzhanlicheng-box-line {
  height: calc(420 / var(--auto-width));
  margin-right: calc(18 / var(--auto-width));
}
.fanzhanlicheng-text-box {
  opacity: 0;
  transform: translateY(2.5rem);
  transition: opacity 1s, transform 1s ease-out;
}
.fanzhanlicheng-animate {
  opacity: 1;
  transform: translateX(0);
}
.fanzhanlicheng-text-box .text-box {
  display: flex;
  align-items: center;
  margin-top: calc(-25 / var(--auto-width));
  margin-bottom: calc(32 / var(--auto-width));
}
.fanzhanlicheng-text-year {
  font-size: calc(80 / var(--auto-width));
  background: linear-gradient(to bottom, #daffcc 0%, #309bff 100%);
  font-family: "AlibabaPuHuiTi";
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: calc(13 / var(--auto-width));
}
.fanzhanlicheng-text-month {
  color: white;
  font-size: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  font-weight: bold;
}
.fanzhanlicheng-text-years {
  color: white;
  font-size: calc(20 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}
.fazhanlicheng-introduction {
  color: white;
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  line-height: calc(38 / var(--auto-width));
}
.fazhanlicheng-box-back .fazhanlicheng-box:nth-of-type(1) {
  margin-top: calc(559 / var(--auto-width));
}
.fazhanlicheng-box-back .fazhanlicheng-box:nth-of-type(2) {
  margin-top: calc(397 / var(--auto-width));
}
.fazhanlicheng-box-back .fazhanlicheng-box:nth-of-type(3) {
  margin-top: calc(122 / var(--auto-width));
}
