.product-container {
  position: relative;
}
.line {
  width: calc(3 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  background: linear-gradient(to bottom, #309bff 78%, #222 100%);
  position: absolute;
  left: 0;
}
.title {
  display: flex;
  justify-content: space-between;
}
.title-product{
  justify-content: flex-start;
}
.title span {
  margin-left: calc(23 / var(--auto-width));
  font-size: calc(62 / var(--auto-width));
  line-height: calc(62 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  color: white;
}
.title button {
  padding: 0 calc(20 / var(--auto-width));
  background-color: #046aff;
  border-radius: calc(60 / var(--auto-width));
  height: calc(55 / var(--auto-width));
  width: calc(142 / var(--auto-width));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title button span {
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
  margin: 0;
  vertical-align: sub;
}
.title button div:hover {
  cursor: pointer;
}
.title button span:hover {
  cursor: pointer;
}
.title button img {
  width: calc(35 / var(--auto-width));
  height: calc(35 / var(--auto-width));
}

/* tab */
.tab {
  margin-top: calc(70 / var(--auto-width));
}
.tab button {
  width: calc(152 / var(--auto-width));
  height: calc(36 / var(--auto-width));
  border-radius: calc(30 / var(--auto-width));
  border: white solid calc(1 / var(--auto-width));
  font-size: calc(16 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}
.actived-button {
  background-color: white;
}
.notactived-button {
  background-color: transparent;
  color: white;
}
hr {
  background-color: rgba(255, 255, 255, 0.4);
  height: calc(1 / var(--auto-width));
  border: none;
  margin: calc(78 / var(--auto-width)) 0 calc(53 / var(--auto-width));
}

/* product item */
.product-item {
  width: calc(612 / var(--auto-width));
  margin-bottom: calc(56 / var(--auto-width));
  cursor: pointer;
}
.product-item:hover .anli-image {
  transform: scale(1.1);
}
.item-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-count: 2;
  column-gap: calc(54 / var(--auto-width));
  max-height: calc(2452 / var(--auto-width));
  position: relative;
}
.item-container1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-count: 2;
  column-gap: calc(54 / var(--auto-width));
  max-height: calc(1356 / var(--auto-width));
  position: relative;
}
@media (max-width: 1000px) {
  .item-container {
    max-height: calc(2600 / var(--auto-width));
  }
}
@media (max-width: 600px) {
  .item-container {
    max-height: calc(2685 / var(--auto-width));
  }
}
 
.product-item-line {
  border: rgba(255, 255, 255, 0.4) solid calc(1 / var(--auto-width));
  margin-top: calc(56 / var(--auto-width));
}
.margin-top {
  margin-top: calc(184 / var(--auto-width));
}
.margin-top1 {
  margin-top: calc(184 / var(--auto-width));
}
.border-bottom {
  border: none;
}
.anli-image {
  width: 100%;
  margin-bottom: calc(38 / var(--auto-width));
  transition: transform 0.3s ease;
}
.product-item span {
  color: white;
  font-family: "AlibabaPuHuiTi";
  font-size: calc(16 / var(--auto-width));
  cursor: pointer;
}

.product-item span:nth-of-type(2) {
  color: rgba(255, 255, 255, 0.8);
}
.arrow {
  width: calc(62 / var(--auto-width));
  height: calc(62 / var(--auto-width));
}
.zhihui {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(62 / var(--auto-width));
  margin: calc(22 / var(--auto-width)) 0;
  cursor: pointer;
}
.zhihui span {
  font-size: calc(30 / var(--auto-width));
}

/* product small item */
.product-small-item {
  display: flex;
  width: calc(612 / var(--auto-width));
  min-height: calc(128 / var(--auto-width));
  padding: 0 calc(100 / var(--auto-width));
  background-color: #0e0f11;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
}
.shijia {
  font-size: calc(80 / var(--auto-width));
  line-height: calc(80 / var(--auto-width));
  font-family: "SofiaProSemiBold";
  background: linear-gradient(
    225deg,
    #076eff 0%,
    #4fabff 31%,
    #b1c5ff 59%,
    #ffddb7 81%,
    #ffb7b7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.column-line {
  width: calc(3 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  background-color: rgba(255, 255, 255, 0.21);
}
.ai-item {
  color: white;
  font-size: calc(30 / var(--auto-width));
  font-family: "AlibabaPuHuiTi";
}

/* hover button */
.icon-chevron {
  overflow: hidden;
  width: calc(62 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-chevron div {
  position: relative;
  width: calc(62 / var(--auto-width));
  height: calc(62 / var(--auto-width));
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0%, 0%) rotate(0.001deg);
  transition: all 0.4s;
  cursor: pointer;
}
.icon-chevron .copy {
  position: absolute;
  transform: translate(-150%, 150%) rotate(0.001deg);
}
.deep button:hover .icon-chevron div,.product-item:hover .icon-chevron div ,.title button:hover .icon-chevron div{
  transform: translate(200%, -200%) rotate(0.001deg);
}
.product-item:hover .icon-chevron .copy,.deep button:hover .icon-chevron .copy,.title button:hover .icon-chevron .copy {
  transform: translate(0%, 0%) rotate(0.001deg);
}
